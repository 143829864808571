<template>
  <div class="section-event px-3 px-sm-4 py-5" id="event">
    <FlowerIl />
    <h1
      data-aos="fade-down"
      data-aos-duration="1500"
      class="h1-couple text-center mb-3"
    >
      Save the Date
    </h1>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center">
      Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta’ala, Kami mengundang
      Bapak/Ibu/Saudara/i, untuk menghadiri Resepsi Pernikahan kami.
    </p>
    <div class="section-event-detail px-3 px-sm-4 py-5 mb-5">
      <!-- Akad Nikah -->
      <Ring height="72" width="67" />
      <h1
        data-aos="fade-down"
        data-aos-duration="1500"
        class="h1-couple text-center my-3"
      >
        Akad Nikah
      </h1>
      <div class="d-flex flex-column gap-2">
        <div data-aos="fade-up" data-aos-duration="1500">
          <Calendar width="22" height="22" class="me-2" />
          <span> {{ event.marriage.date }} </span>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500">
          <Clock class="me-2" />
          <span>
            Pukul {{ event.marriage.start }} s.d {{ event.marriage.end }}
          </span>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          class="d-flex flex-row align-items-start"
        >
          <div>
            <LocationIc class="me-2" />
          </div>
          <div class="d-flex flex-column gap-1">
            <span class="fw-bold"> {{ event.marriage.location.name }} </span>
            <span>
              {{ event.marriage.address }}
            </span>
          </div>
        </div>
      </div>
      <!-- <a
        :href="event.marriage.location.link"
        target="_blank"
        class="btn btn-hy-primary mt-3"
      >
        Buka Maps
      </a> -->
      <!-- Akad Nikah End -->
      <!-- Resepsi Pernikahan -->
      <CoupleIc class="mt-5" />
      <h1
        data-aos="fade-down"
        data-aos-duration="1500"
        class="h1-couple text-center my-3"
      >
        Resepsi Pernikahan
      </h1>
      <div class="d-flex flex-column gap-2">
        <div data-aos="fade-up" data-aos-duration="1500">
          <Calendar width="22" height="22" class="me-2" />
          <span>{{ event.reception.date }}</span>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500">
          <Clock class="me-2" />
          <span
            >Pukul {{ event.reception.start }} s.d {{ event.reception.end }}
          </span>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          class="d-flex flex-row align-items-start"
        >
          <div>
            <LocationIc class="me-2" />
          </div>
          <div class="d-flex flex-column gap-1">
            <span class="fw-bold"> {{ event.reception.location.name }} </span>
            <span>
              {{ event.reception.address }}
            </span>
          </div>
        </div>
      </div>
      <a
        :href="event.reception.location.link"
        target="_blank"
        class="btn btn-hy-primary mt-3"
      >
        Buka Maps
      </a>
      <!-- Resepsi Pernikahan End -->
      <!-- Video Conference & Youtube -->
      <div :class="videoConference">
        <VidConference class="mt-5" />
        <h1 class="h1-couple text-center my-3">Video Conference</h1>
        <p class="text-center mb-4">
          Temui kami secara virtual untuk menyaksikan acara pernikahan kami yang
          disiarkan langsung, dengan cara menekan tombol di bawah ini :
        </p>
        <img
          src="@/assets/logos/logo-zoom.png"
          alt="zoom"
          class="img-fluid mb-2"
        />
        <p class="text-center mb-3">
          Meeting ID : {{ live.zoom.meetid }} <br />
          Password : {{ live.zoom.password }}
        </p>
        <a :href="live.zoom.link" class="btn btn-hy-primary" target="_blank">
          Live Zoom
        </a>
      </div>
      <!-- <div :class="youtubeLive">
        <img
          src="@/assets/logos/logo-youtube.png"
          alt="youtube"
          class="img-fluid mt-4 mb-2"
          width="100px"
        />
        <a :href="live.youtube" class="btn btn-hy-primary" target="_blank">
          Live on Youtube
        </a>
      </div> -->
      <!-- Video Conference & Youtube end -->
      <!-- Countdown Time -->
      <h1 class="mt-5 text-center">Waktu Menuju Acara</h1>
      <count-down
        :year="year"
        :month="month"
        :date="date"
        :hour="hour"
        :minute="minute"
        :second="0"
        :milisecond="0"
      />
      <add-to-calendar
        :title="calendar.title"
        :location="calendar.location"
        :start="new Date(year, month, date, hour, minute)"
        :end="new Date(year, month, date, hour + calendar.duration, minute)"
        :details="calendar.details"
        inline-template
      >
        <div>
          <google-calendar class="btn btn-hy-primary" id="google-calendar">
            Add to Calendar
          </google-calendar>
        </div>
      </add-to-calendar>
      <!-- Countdown Time end -->
    </div>
    <!-- Protokol Kesehatan -->
    <!-- <Health />
    <h1
      data-aos="fade-down"
      data-aos-duration="1500"
      class="h1-couple text-center my-3"
    >
      Protokol Kesehatan
    </h1>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center mb-4">
      Sehubungan dengan kondisi saat ini, untuk mematuhi himbauan pemerintah
      dalam pencegahan penyebaran Covid-19, maka diharapkan bapak/ibu/saudara/i
      tamu undangan untuk :
    </p>
    <div class="ic-health-wrapper p-3 mt-3">
      <MaskIc />
    </div>
    <p
      data-aos="fade-down"
      data-aos-duration="1500"
      class="text-center fw-bold mt-2"
    >
      Selalu memakai masker selama acara berlangsung
    </p>
    <div class="ic-health-wrapper p-3 mt-3">
      <Handwash />
    </div>
    <p
      data-aos="fade-down"
      data-aos-duration="1500"
      class="text-center fw-bold mt-2"
    >
      Mencuci tangan sebelum dan sesudah memasuki lokasi acara
    </p>
    <div class="ic-health-wrapper p-3 mt-3">
      <KeepDistance />
    </div>
    <p
      data-aos="fade-down"
      data-aos-duration="1500"
      class="text-center fw-bold mt-2"
    >
      Tidak berkerumun, dengan menjaga jarak satu sama lain +/- 2 meter
    </p>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center mt-5">
      Terima kasih atas pengertian bapak/ibu/saudara/i, semoga dengan melakukan
      protokol kesehatan diatas, kita semua dapat terjaga dari Covid-19
    </p> -->
    <!-- Protokol Kesehatan end -->
  </div>
</template>

<script>
const Calendar = () => import("@/assets/icons/icon-calendar.svg");
const Clock = () => import("@/assets/icons/icon-clock.svg");
const CoupleIc = () => import("@/assets/icons/icon-couple.svg");
// const Handwash = () => import("@/assets/icons/icon-handwash.svg");
// const Health = () => import("@/assets/icons/icon-health-insurance.svg");
// const KeepDistance = () => import("@/assets/icons/icon-keep-distance.svg");
const LocationIc = () => import("@/assets/icons/icon-location.svg");
// const MaskIc = () => import("@/assets/icons/icon-mask.svg");
const Ring = () => import("@/assets/icons/icon-ring.svg");
const VidConference = () => import("@/assets/icons/icon-video-conference.svg");
const FlowerIl = () => import("@/assets/illustrations/flower-section.svg");

const CountDown = () => import("@/components/CountDown.vue");
var AddToCalendar = require("vue-add-to-calendar");

export default {
  name: "EventSection",
  props: [
    "event",
    "live",
    "year",
    "month",
    "date",
    "hour",
    "minute",
    "calendar",
  ],
  components: {
    Calendar,
    Clock,
    CoupleIc,
    // Handwash,
    // Health,
    // KeepDistance,
    LocationIc,
    // MaskIc,
    Ring,
    VidConference,
    CountDown,
    FlowerIl,
    AddToCalendar,
  },
  computed: {
    videoConference() {
      return this.live.zoom.meetid.length &&
        this.live.zoom.password.length &&
        this.live.zoom.link.length !== 0
        ? "section-show"
        : "d-none";
    },
    youtubeLive() {
      return this.live.youtube.length !== 0 ? "section-show" : "d-none";
    },
  },
};
</script>